



































































































































































::v-deep .set-container {
    margin: 20px;
    background: #fff;
    height: calc(100% - 40px);
    border: 1px solid #e6e6e6;
    border-radius: 4px;

    & > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}

.main-content {
    margin: 20px;
    background: #fff;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
}

.tab {
    text-align: right;
}

.content {
    flex: 1;
    height: 1%;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.form-content {
    flex: 1;
    height: 1%;
    margin-top: 20px;
}

.el-input {
    width: 300px;
}

.footer-box {
    justify-content: start;
    display: flex;
}

.form-item {
    display: flex;
    height: 40px;
    margin-top: 20px;
    width: 450px;

    &:first-child {
        margin-top: 0;
    }

    .item-title {
        width: 150px;
        background: #F6F6F6;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #eee;
    }

    .item-right {
        height: 100%;
        flex: 1;
    }

    .item-value {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #eee;
    }
}

